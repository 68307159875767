<template>

  <div>

    <p class="primary--text">{{$t('CHANNEL.PARAMS')}}</p>
    <v-row class="mt-0">
      <v-col cols="6" class="pt-0 pb-0">
        <v-text-field
          dense
          v-model="controller_name"
          :rules="[$rules.required, $rules.maxlength(255)]"
          :label="$t('INPUT.CONTROLLER_NAME')"
          :placeholder="$t('INPUT.CONTROLLER_NAME')"
          @change="$emit('input', {controller_name: $event})"
          outlined
          required
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-0">
        <v-select
          outlined
          dense
          multiple
          :items="['offer', 'text', 'image', 'carousel', 'html', 'audio', 'video']"
          v-model="types"
          required
          small-chips
          :rules="[$rules.required, $rules.minlength(1)]"
          @change="$emit('input', {types: $event})"
          :label="$t('INPUT.CHANNEL_TYPES')"
          :placeholder="$t('INPUT.CHANNEL_TYPES')"
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <v-select
          outlined
          dense
          multiple
          :items="['budget', 'credit']"
          v-model="contract_types"
          required
          small-chips
          :rules="[$rules.required, $rules.minlength(1)]"
          @change="$emit('input', {contract_types: $event})"
          :label="$t('INPUT.CONTRACT_TYPES')"
          :placeholder="$t('INPUT.CONTRACT_TYPES')"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12" class="pt-0 pb-0">
        <p>{{$t('CHANNEL.ACCOUNT_FIELDS')}}</p>
        <v-btn rounded small depressed  @click="addParams('account_params', {type:'text',name:''})">
          <v-icon class="mr-2">mdi-plus</v-icon>
          {{$t('COMMON.ADD')}}
        </v-btn>
        <template v-for="(item,i) in account_params">
          <v-row :key="'fi'+i" class="mt-1">
            <v-col cols="3">
              <v-select
                class="text-nowrap"
                hide-details
                :items="['string', 'number', 'boolean']"
                v-model="account_params[i].type"
                :label="$t('INPUT.TYPE')"
                @change="emitAccountParams"
                required
                outlined
                dense
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                hide-details
                :rules="[$rules.required]"
                v-model="account_params[i].name"
                :label="$t('INPUT.NAME')"
                @change="emitAccountParams"
                outlined
                dense
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <v-checkbox
                dense
                class="mt-1 mb-2"
                v-model="account_params[i].required"
                :label="$t('COMMON.REQUIRED')"
                @change="emitAccountParams"
                :value="true"
                hide-details
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn icon @click="removeParams('account_params', i)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <p>{{$t('CHANNEL.PARAMS_FIELDS')}}</p>
        <v-btn rounded small depressed  @click="addParams('channel_params', {type:'text',name:''})">
          <v-icon class="mr-2">mdi-plus</v-icon>
          {{$t('COMMON.ADD')}}
        </v-btn>
        <template v-for="(item,i) in channel_params">
          <v-row :key="'fi'+i" class="mt-1">
            <v-col cols="3">
              <v-select
                class="text-nowrap"
                hide-details
                :items="['string', 'number', 'boolean']"
                v-model="channel_params[i].type"
                :label="$t('INPUT.TYPE')"
                @change="emitChannelParams"
                required
                outlined
                dense
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                hide-details
                :rules="[$rules.required]"
                v-model="channel_params[i].name"
                :label="$t('INPUT.NAME')"
                @change="emitChannelParams"
                outlined
                dense
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <v-checkbox
                dense
                class="mt-1 mb-2"
                v-model="channel_params[i].required"
                :label="$t('COMMON.REQUIRED')"
                @change="emitChannelParams"
                :value="true"
                hide-details
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn icon @click="removeParams('channel_params', i)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name:"FormChannelCompactPricing",
  data: () => ({
    valid: false,
    //
    controller_name: undefined,
    types: [],
    contract_types: [],
    account_params: [],
    channel_params: [],
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    emitChannelParams () {
      this.emitParams('channel_params')
    },
    emitAccountParams () {
      this.emitParams('account_params')
    },
    emitParams (type_params) {
      if (this[type_params]) {
        this.$emit('input', {[type_params]: this.$clone(this[type_params])})
      } else {
        console.error('No param:' + type_params)
      }
    },
    addParams (type_params, value) {
      if (this[type_params]) {
        this[type_params].push(value)
        this.emitParams(type_params)
      } else {
        console.error('No param:' + type_params)
      }
    },
    removeParams (type_params, key) {
      if (this[type_params]) {
        this[type_params].splice(key, 1)
        this.emitParams(type_params)
      } else {
        console.error('No param:' + type_params)
      }
    },
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
